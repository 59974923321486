import { Vue, Component } from "vue-property-decorator";

@Component
export default class FadeIn extends Vue {
  private activeClassName = "animate-fade-in";

  private onAnimationEnd() {
    this.activeClassName = "";
  }
}
